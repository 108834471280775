import React, { useState } from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import moment from "moment";
import { Skeleton } from "@material-ui/lab";
import useInterval from "../../../utils/useInterval";

type CountdownProps = {
  presaleStart: number;
};

export default function Countdown({ presaleStart }: CountdownProps) {
  const classes = useStyles();
  const isBeforePresale = moment().isBefore(moment.unix(presaleStart));
  const startsAt = moment();
  const endsAt = moment.unix(presaleStart);
  const completed = moment().diff(startsAt, "seconds");
  const length = endsAt.diff(startsAt, "seconds");
  const secondsRemaining = length - completed;
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const convertToTime = (secondsRemaining: number) => {
    const days = Math.floor(secondsRemaining / (3600 * 24));
    const hours = Math.floor((secondsRemaining % (3600 * 24)) / 3600);
    const minutes = Math.floor((secondsRemaining % 3600) / 60);
    const seconds = Math.floor(secondsRemaining % 60);
    return { days, hours, minutes, seconds };
  };

  useInterval(() => {
    setTimeRemaining(convertToTime(secondsRemaining));
  }, 1000);

  return !isBeforePresale ? null : (
    <div className={classes.root}>
      <Typography className={classes.title} variant={"h6"}>
        Starts in
      </Typography>
      <div className={classes.counter}>
        {isBeforePresale ? (
          <>
            {" "}
            <div className={classes.countContainer}>
              <Typography
                variant={"h6"}
                color={"inherit"}
                className={classes.count}
              >
                {timeRemaining.days} Days
              </Typography>
            </div>
            <div className={classes.countContainer}>
              <Typography
                variant={"h6"}
                color={"inherit"}
                className={classes.count}
              >
                {timeRemaining.hours} Hours
              </Typography>
            </div>
            <div className={classes.countContainer}>
              <Typography
                variant={"h6"}
                color={"inherit"}
                className={classes.count}
              >
                {timeRemaining.minutes} Minutes
              </Typography>
            </div>
            <div className={classes.countContainer}>
              <Typography
                variant={"h6"}
                color={"inherit"}
                className={classes.count}
              >
                {timeRemaining.seconds} Seconds
              </Typography>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
    },
    title: {
      fontWeight: 300,
      fontFamily: "'Poppins', sans-serif;",
      marginRight: theme.spacing(1),
      color: theme.palette.background.green,
    },
    counter: {
      display: "flex",
      justifyContent: "flex-start",
    },
    countContainer: {
      textAlign: "center",
      marginRight: theme.spacing(1),
    },
    date: {
      textTransform: "uppercase",
    },
    count: {
      fontWeight: 300,
      color: theme.palette.background.green,
      fontFamily: "'Poppins', sans-serif;",
    },
    countText: {
      fontWeight: 300,
      color: theme.palette.common.white,
      fontFamily: "'Poppins', sans-serif;",
    },
  })
);
