import React, { useState } from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import { Grid, Typography, Button, useMediaQuery } from "@material-ui/core";
import useInterval from "../../../utils/useInterval";
import { config } from "../../../config/config";
import moment from "moment";
import SupplyCounter from "./SupplyCounter";

type WelcomeSectionProps = {
  isConnected: boolean;
  requestAccount: () => void;
  fetchPublicAmountMinted: () => Promise<number>;
  disconnect?: () => void;
};

export default function WelcomeSection({
  isConnected,
  requestAccount,
  disconnect,
  fetchPublicAmountMinted,
}: WelcomeSectionProps) {
  const classes = useStyles({ isConnected });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isGeneralSaleActive, setIsGeneralSaleActive] = useState(false);

  useInterval(() => {
    const generalSaleHasStarted = moment().isAfter(
      moment.unix(config.generalSaleTimestamp)
    );

    if (generalSaleHasStarted) {
      setIsGeneralSaleActive(true);
    }
  }, 1000);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography className={classes.title} variant={isMobile ? "h3" : "h2"}>
          Today You Are Becoming a Legend
        </Typography>
        <Typography className={classes.text} variant={isMobile ? "h6" : "h5"}>
          Be the first to get your ticket to the exclusive House of Legends; A
          prosperity mindset focused NFT that gives back and invests in
          innovation.
        </Typography>
        <Typography className={classes.text} variant={isMobile ? "h6" : "h5"}>
          <span style={{ fontWeight: "bold" }}>Phase 1:</span> Dec 19
        </Typography>
        <Typography className={classes.text} variant={isMobile ? "h6" : "h5"}>
          <span style={{ fontWeight: "bold" }}>Phase 2:</span> Dec 20
        </Typography>
        <Typography className={classes.text} variant={isMobile ? "h6" : "h5"}>
          <span style={{ fontWeight: "bold" }}>General Sale:</span> Dec 21
        </Typography>
        <Typography className={classes.text} variant={isMobile ? "h6" : "h5"}>
          <span style={{ fontWeight: "bold" }}>Reveal Date:</span> Dec 22
        </Typography>
        <Typography className={classes.text} variant={isMobile ? "h6" : "h5"}>
          With every Legend you mint, you provide a lifetime of clean water to a
          family. 77 communities, 50,000 lives changed thanks to H.O.L. holders.
        </Typography>
        <SupplyCounter fetchPublicAmountMinted={fetchPublicAmountMinted} />

        <div className={classes.mintContainer}>
          <Button
            variant={"contained"}
            size={"large"}
            color={"primary"}
            className={classes.mintButton}
            disabled={isConnected}
            onClick={requestAccount}
            endIcon={isConnected ? <div className={classes.greenDot} /> : null}
          >
            {isConnected ? "Wallet Connected!" : "Connect Wallet"}
          </Button>
          {isConnected && disconnect ? (
            <Button className={classes.disconnectButton} onClick={disconnect}>
              disconnect
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles<Theme, { isConnected: boolean }>((theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.background.default,
      display: "flex",
      justifyContent: "center",
      padding: theme.spacing(2, 3),
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(2, 3),
      },
      [theme.breakpoints.up("xl")]: {
        padding: theme.spacing(2, 3),
      },
    },
    content: { maxWidth: 800 },
    title: {
      textAlign: "center",
      fontWeight: 700,
      color: theme.palette.common.white,
      marginBottom: theme.spacing(4),
    },
    text: {
      textAlign: "center",
      fontWeight: 300,
      color: theme.palette.common.white,
      marginBottom: theme.spacing(2),
      fontFamily: "'Poppins', sans-serif;",
    },
    mintContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: theme.spacing(2),
    },
    mintButton: {
      marginTop: theme.spacing(3),
      padding: theme.spacing(1.5, 2.5),
      fontWeight: 700,
      fontFamily: "'Poppins', sans-serif;",
      textTransform: "uppercase",
      background: theme.palette.primary.main,
      color: theme.palette.common.black,
      fontSize: ({ isConnected }) =>
        isConnected
          ? theme.typography.h6.fontSize
          : theme.typography.h5.fontSize,
      borderRadius: 0,
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.h3.fontSize,
      },
      "&.MuiButton-contained.Mui-disabled": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.black,
      },
    },
    greenDot: {
      height: 12,
      width: 12,
      borderRadius: 10,
      background: theme.palette.success.main,
    },
    img: {
      maxWidth: 350,
      maxHeight: 350,
      height: "100%",
      width: "100%",
      borderRadius: theme.shape.borderRadius,
    },
    disconnectButton: {
      marginTop: theme.spacing(1),
      color: theme.palette.common.white,
    },
  })
);
