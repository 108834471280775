export function getUserError(message: string) {
  switch (message) {
    case "execution reverted: exceeds max supply":
      return "We are SOLD OUT! Find us on OpenSea.io";
    case "execution reverted: Phase one must be active":
      return "Phase 1 must be active to mint.";
    case "execution reverted: not whitelisted for phase one":
      return "Sorry, you're not eligible for whitelist minting";
    case "execution reverted: exceeds the account's quota":
      return "Oops! You minted too many.";
    case "execution reverted: exceeds max public supply":
      return "We are SOLD OUT! Find us on OpenSea.io";
    case "execution reverted: Ether value sent is not correct":
      return "Ether value is not enough to mint";
    case "execution reverted: Phase two must be active":
      return "Phase 2 must be active to mint";
    case "execution reverted: not whitelisted for phase two":
      return "Sorry, you're not eligible for whitelist minting";
    case "execution reverted: Sale must be active":
      return "Sale must be active to mint. Please check sale dates.";
    case "execution reverted: exceeds maximum purchase amount":
      return "Oops! You chose too many. Please try again.";
    case "execution reverted: not on gift list":
      return "Sorry, you are not eligible for a token claim";
    default:
      return message;
  }
}
