import { useCallback, useEffect, useRef, useState } from "react";
import { config } from "../config/config";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { WalletLink } from "walletlink";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseIcon from "../assets/images/coinbase.svg";
import MetamaskIcon from "../assets/images/metamask.svg";
import { openInNewTab } from "./openLink";
import { isMobile } from "react-device-detect";
import { ethers } from "ethers";
import { CHAIN_HEX, contractAddress, PRICE } from "./contract";
import HouseOfLegends from "../HouseOfLegends.json";
import { serializeError } from "eth-rpc-errors";
import { getUserError } from "./getUserError";

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: config.infuraId,
    },
  },
  "custom-metamask": {
    display: {
      logo: MetamaskIcon,
      name: "MetaMask",
      description: "Connect to your MetaMask Wallet",
    },
    package: true,
    connector: async () => {
      let provider = null;
      if (window?.ethereum) {
        const providers = window.ethereum.providers;
        // if there are no multiple injected providers available, return the injected provider
        provider = providers
          ? providers.find((p) => p.isMetaMask)
          : window.ethereum; // <-- LOOK HERE
        try {
          await provider.request({ method: "eth_requestAccounts" });
        } catch (error) {
          throw new Error("User Rejected");
        }
      } else {
        if (isMobile) {
          openInNewTab(config.metamaskDeeplink);
          return;
        }
      }
      console.log("MetaMask provider", provider);
      return provider;
    },
  },
  "custom-coinbase": {
    display: {
      logo: CoinbaseIcon,
      name: "Coinbase",
      description: "Scan with WalletLink to connect",
    },
    options: {
      appName: "House of Legends", // Your app name
      infuraId: config.infuraId,
      networkUrl: `https://mainnet.infura.io/v3/${config.infuraId}`,
      chainId: 1,
    },
    package: WalletLink,
    connector: async (_, options) => {
      const { appName, networkUrl, chainId } = options;
      const walletLink = new WalletLink({
        appName,
      });
      const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
      await provider.enable();
      await provider.send("eth_requestAccounts");
      return provider;
    },
  },
};

const web3Modal = new Web3Modal({
  cacheProvider: false, // optional
  disableInjectedProvider: true,

  providerOptions, // required
});

export function useEthereum() {
  const [provider, setProvider] = useState<any>(null);
  const web3Ref = useRef(window?.ethereum ? new Web3(window.ethereum) : null);

  const [account, setAccount] = useState<string | null>(null);
  const [isConnectedToChain, setIsConnectedToChain] = useState(true);

  async function handleDisconnect() {
    if (provider?.close) {
      console.log(provider.close());
    }
    await web3Modal.clearCachedProvider();
    setProvider(null);
  }

  const checkForExistingAccount = useCallback(async () => {
    console.log("checking for existing account");
    if (web3Ref.current) {
      const web3 = web3Ref.current;
      setProvider(web3.eth.currentProvider);
      const accounts = await web3.eth.getAccounts();
      if (accounts?.length) {
        setAccount(accounts[0]);
      }
    } else {
      console.log("ethereum not detected");
    }
  }, [provider, web3Ref.current]);

  async function handleSwitchNetworkRequest() {
    const currentProvider = await web3Ref.current?.currentProvider;
    if (currentProvider?.request) {
      await currentProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: config.contractChainId }],
      });
    }
  }

  const handleChainVerification = useCallback(async () => {
    if (provider) {
      try {
        const chainId = await web3Ref.current?.eth?.getChainId();
        const isConnectedToCorrectChain = chainId
          ? CHAIN_HEX[chainId] === config.contractChainId
          : false;
        // We recommend reloading the page, unless you must do otherwise
        setIsConnectedToChain(isConnectedToCorrectChain);
        if (!isConnectedToCorrectChain) {
          handleSwitchNetworkRequest();
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [provider, web3Ref.current]);

  const requestAccount = useCallback(async () => {
    await web3Modal.clearCachedProvider();
    const newProvider = await web3Modal.connect();
    web3Ref.current = new Web3(newProvider);
    setProvider(newProvider);
  }, [web3Ref.current]);

  function handleChainChanged() {
    // We recommend reloading the page, unless you must do otherwise
    window.location.reload();
  }

  function handleAccountChange(accounts: string[]) {
    console.log('accounts changed"', accounts);
    if (accounts?.length) {
      setAccount(accounts[0]);
    } else {
      setAccount(null);
    }
  }

  const subscribeProvider = useCallback(
    async (provider) => {
      if (provider) {
        console.log("subscribing provider");
        await checkForExistingAccount();
        provider?.on("accountsChanged", handleAccountChange);
        provider?.on("chainChanged", handleChainChanged);
        provider?.on("connect", handleChainChanged);
        provider?.on("disconnect", handleChainChanged);
      }
    },
    [provider]
  );

  const unsubscribeProvider = useCallback(
    (provider) => {
      if (provider?.removeListener) {
        console.log("unsubscribing provider");
        provider?.removeListener("accountsChanged", handleAccountChange);
        provider?.removeListener("chainChanged", handleChainChanged);
        provider?.removeListener("connect", handleChainChanged);
        provider?.removeListener("disconnect", handleChainChanged);
      }
    },
    [provider]
  );

  useEffect(() => {
    function handleEthereum() {
      web3Ref.current = new Web3(window.ethereum);
    }
    if (window.ethereum) {
      handleEthereum();
    } else {
      window.addEventListener("ethereum#initialized", handleEthereum, {
        once: true,
      });

      // If the event is not dispatched by the end of the timeout,
      // the user probably doesn't have MetaMask installed.
      setTimeout(handleEthereum, 3000); // 3 seconds
    }
  }, []);

  useEffect(() => {
    checkForExistingAccount();
  }, []);

  useEffect(() => {
    handleChainVerification();
    subscribeProvider(provider);
    return () => unsubscribeProvider(provider);
  }, [provider]);

  // Contract functions

  function getContractAndSigner() {
    const ethersProvider = new ethers.providers.Web3Provider(provider);
    const signer = ethersProvider.getSigner();
    const houseOfLegends = new ethers.Contract(
      contractAddress,
      HouseOfLegends.abi,
      signer
    );
    return { houseOfLegends, signer } as const;
  }

  async function mint(count: number) {
    console.log("contract:", contractAddress);
    console.log("mint => ", window.ethereum);
    if (account) {
      const { houseOfLegends, signer } = getContractAndSigner();
      const value = PRICE.mul(count);
      try {
        const transaction = await houseOfLegends.mint(
          await signer.getAddress(),
          count,
          {
            value,
          }
        );
        await transaction.wait();
      } catch (e) {
        const error = serializeError(e);
        console.error("mint error", error);
        const message =
          error?.data?.originalError?.error?.message || "An error has occured";
        if (e?.code === "INSUFFICIENT_FUNDS") {
          throw new Error(
            `You don't have enough eth to mint this item. Please send eth to your account to make the purchase.`
          );
        } else if (e?.code === 4001) {
          throw new Error(`Transaction rejected by user`);
        } else {
          throw new Error(getUserError(message));
        }
      }
    } else {
      requestAccount();
    }
  }

  async function phaseOneMint(count: number, proof: string[]) {
    console.log("contract:", contractAddress);
    console.log("phaseOneMint => ", window.ethereum);
    if (account) {
      const { houseOfLegends, signer } = getContractAndSigner();
      const value = PRICE.mul(count);
      try {
        const transaction = await houseOfLegends.phaseOnePresaleMint(
          await signer.getAddress(),
          count,
          proof,
          {
            value,
          }
        );
        await transaction.wait();
      } catch (e) {
        const error = serializeError(e);
        console.error("phaseOneMint mint error", error);
        const message =
          error?.data?.originalError?.error?.message || "An error has occured";
        if (e?.code === "INSUFFICIENT_FUNDS") {
          throw new Error(
            `You don't have enough eth to mint this item. Please send eth to your account to make the purchase.`
          );
        } else if (e?.code === 4001) {
          throw new Error(`Transaction rejected by user`);
        } else {
          throw new Error(getUserError(message));
        }
      }
    } else {
      requestAccount();
    }
  }

  async function phaseTwoMint(count: number, proof: string[]) {
    console.log("contract:", contractAddress);
    console.log("phaseTwoMint => ", window.ethereum);
    if (account) {
      const { houseOfLegends, signer } = getContractAndSigner();
      const value = PRICE.mul(count);
      try {
        const transaction = await houseOfLegends.phaseTwoPresaleMint(
          await signer.getAddress(),
          count,
          proof,
          {
            value,
          }
        );
        await transaction.wait();
      } catch (e) {
        const error = serializeError(e);
        console.error("phaseTwoMint mint error", error);
        const message =
          error?.data?.originalError?.error?.message || "An error has occured";
        if (e?.code === "INSUFFICIENT_FUNDS") {
          throw new Error(
            `You don't have enough eth to mint this item. Please send eth to your account to make the purchase.`
          );
        } else if (e?.code === 4001) {
          throw new Error(`Transaction rejected by user`);
        } else {
          throw new Error(getUserError(message));
        }
      }
    } else {
      requestAccount();
    }
  }

  async function giftMint(count: number, proof: string[]) {
    console.log("contract:", contractAddress);
    console.log("giftMint => ", window.ethereum);
    if (account) {
      const { houseOfLegends, signer } = getContractAndSigner();
      try {
        const transaction = await houseOfLegends.giftMint(
          await signer.getAddress(),
          count,
          proof
        );
        await transaction.wait();
      } catch (e) {
        const error = serializeError(e);
        console.error("gift claim mint error", error);
        const message =
          error?.data?.originalError?.error?.message || "An error has occured";
        if (e?.code === "INSUFFICIENT_FUNDS") {
          throw new Error(
            `You don't have enough eth to mint this item. Please send eth to your account to make the purchase.`
          );
        } else if (e?.code === 4001) {
          throw new Error(`Transaction rejected by user`);
        } else {
          throw new Error(getUserError(message));
        }
      }
    } else {
      requestAccount();
    }
  }

  // call the smart contract, read the current greeting value
  async function fetchPublicAmountMinted() {
    try {
      const ethersProvider = new ethers.providers.Web3Provider(provider);
      const houseOfLegends = new ethers.Contract(
        contractAddress,
        HouseOfLegends.abi,
        ethersProvider
      );
      return await houseOfLegends.publicAmountMinted();
    } catch (e) {
      console.log(e);
    }
  }

  return {
    account,
    requestAccount,
    isConnectedToChain,
    mint,
    phaseOneMint,
    phaseTwoMint,
    giftMint,
    fetchPublicAmountMinted,
    disconnect: provider?.close ? handleDisconnect : undefined,
    isMetamask: !!provider?.isMetamask,
  } as const;
}
