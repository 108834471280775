import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { AppBar, Toolbar } from "@material-ui/core";
import SocialMediaLinks from "./SocialMediaLinks";
import { config } from "../../../config/config";
import logo from "../../../assets/images/hol-logo.webp";

export default function Navbar() {
  const classes = useStyles();

  return (
    <AppBar position={"relative"} className={classes.container}>
      <Toolbar>
        <div className={classes.toolbar}>
          <div
            onClick={() => {
              window.location.href = config.homepage;
            }}
            className={classes.logo}
          >
            <img src={logo} alt={"House of Legends"} />
          </div>
          <SocialMediaLinks />
        </div>
      </Toolbar>
    </AppBar>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      zIndex: 1,
      display: "flex",
      background: theme.palette.background.default,
      boxShadow: "none",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
      },
    },
    logo: {
      marginBottom: theme.spacing(2),
      cursor: "pointer",
    },
    toolbar: {
      padding: theme.spacing(4),
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);
