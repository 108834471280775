type Config = {
  homepage: string;
  openSeaUrl: string;
  etherscanUrl: string;
  infuraId: string;
  rinkbeyContractAddress: string;
  mainnetContractAddress: string;
  contractChainId: string;
  metamaskDeeplink: string;
  phaseOneSaleTimestamp: number;
  phaseTwoSaleTimestamp: number;
  generalSaleTimestamp: number;
  discordUrl: string;
  twitterUrl: string;
  instagramUrl: string;
};

export const config: Config = {
  homepage: "https://houseoflegends.art/",
  openSeaUrl: "https://opensea.io/collection/CONTRACT_NAME",
  etherscanUrl:
    "https://etherscan.io/address/0x8C714199d2eA08CC1f1F39A60f5cD02aD260A1e3",
  infuraId: "4c84cc7f724e48b293949ad57ab1c193",
  rinkbeyContractAddress: "0xAd51393e643be7612E2Ac8c04419EB51C05280fe",
  mainnetContractAddress: "0x8C714199d2eA08CC1f1F39A60f5cD02aD260A1e3",
  contractChainId: "0x1",
  metamaskDeeplink: "https://metamask.app.link/dapp/mint.houseoflegends.art/",
  phaseOneSaleTimestamp: 1639922400,
  phaseTwoSaleTimestamp: 1640012400,
  generalSaleTimestamp: 1640102400,
  discordUrl: "https://discord.com/invite/jnzPPXDKms",
  twitterUrl: "https://twitter.com/TheLegendsNFTs",
  instagramUrl: "https://www.instagram.com/houseoflegends.nft/",
};
