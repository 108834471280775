import React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import {
  Button,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import DaliImage from "../../../assets/images/main-token-2.webp";
import { Twitter as TwitterIcon } from "@material-ui/icons";
import DiscordIcon from "../../../assets/images/discord-icon.svg";
import { openInNewTab } from "../../../utils/openLink";
import { config } from "../../../config/config";

export default function Footer() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div className={classes.root}>
      <Grid container justify={"space-between"}>
        <Grid item xs={12} md={6}>
          <div className={classes.linkContainer}>
            <Typography
              className={classes.title}
              variant={isMobile ? "h4" : "h3"}
            >
              Verified Smart Contract
            </Typography>
            <Typography
              className={classes.text}
              variant={isMobile ? "h4" : "h3"}
              component={"a"}
              onClick={() => openInNewTab(config.etherscanUrl)}
            >
              View On Etherscan
            </Typography>
            <div className={classes.socialContainer}>
              <div className={classes.socialItemRight}>
                <IconButton
                  color={"inherit"}
                  onClick={() => openInNewTab(config.twitterUrl)}
                >
                  <TwitterIcon fontSize={"large"} />
                </IconButton>
              </div>
              <div className={classes.socialItemLeft}>
                <IconButton
                  color={"inherit"}
                  onClick={() => openInNewTab(config.discordUrl)}
                >
                  <img
                    className={classes.discordIcon}
                    src={DiscordIcon}
                    alt={"link to discord"}
                  />
                </IconButton>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.imgContainer}>
            <img src={DaliImage} alt={"tiger"} className={classes.img} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.common.black,
      padding: theme.spacing(8, 2, 0),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(8, 6, 0),
      },
      [theme.breakpoints.up("xl")]: {
        padding: theme.spacing(8, 12, 0),
      },
    },
    title: {
      fontWeight: 700,
      marginBottom: theme.spacing(1),
      color: theme.palette.common.white,
    },
    text: {
      fontWeight: 700,
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
    imgContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "flex-end",
    },
    img: {
      maxWidth: 350,
      maxHeight: 350,
      height: "100%",
      width: "100%",
      border: "none",
    },
    linkContainer: {
      paddingTop: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-end",
    },
    socialContainer: {
      display: "flex",
    },
    socialItemRight: {
      borderTop: "3px solid white",
      borderRight: "3px solid white",
      paddingTop: theme.spacing(2),
      width: 140,
      height: 50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.common.white,
      [theme.breakpoints.up("md")]: {
        width: 210,
      },
    },
    socialItemLeft: {
      borderTop: "3px solid white",
      paddingTop: theme.spacing(2),
      width: 140,
      height: 50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.up("md")]: {
        width: 210,
      },
    },
    discordIcon: {
      width: 35,
      height: 35,
    },
  })
);
