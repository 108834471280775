import React, { useEffect, useState } from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import { Typography, useMediaQuery } from "@material-ui/core";
import Minter from "./Minter";
import Countdown from "./Countdown";
import useInterval from "../../../utils/useInterval";
import moment from "moment";
import { config } from "../../../config/config";

type MintSectionProps = {
  mint: (count: number) => void;
  phaseOneMint: (count: number) => void;
  phaseTwoMint: (count: number) => void;
  giftMint: (count: number) => void;
  isOnCorrectChain: boolean;
};

const PHASE_ONE_START = config.phaseOneSaleTimestamp;
const PHASE_TWO_START = config.phaseTwoSaleTimestamp;
const GENERAL_SALE_START = config.generalSaleTimestamp;
const SOLD_OUT = true;

export default function MintSection({
  mint,
  phaseOneMint,
  phaseTwoMint,
  giftMint,
  isOnCorrectChain,
}: MintSectionProps) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isPhaseOneActive, setIsPhaseOneActive] = useState(false);
  const [isPhaseTwoActive, setIsPhaseTwoActive] = useState(false);
  const [isGeneralSaleActive, setIsGeneralSaleActive] = useState(false);
  const [showGiftSection, setShowGiftSection] = useState(false);

  useInterval(() => {
    const generalSaleHasStarted = moment().isAfter(
      moment.unix(GENERAL_SALE_START)
    );
    const phaseOneHasStarted = moment().isAfter(moment.unix(PHASE_ONE_START));
    const phaseTwoHasStarted = moment().isAfter(moment.unix(PHASE_TWO_START));
    if (generalSaleHasStarted) {
      setIsGeneralSaleActive(true);
    }
    if (phaseOneHasStarted) {
      setIsPhaseOneActive(true);
    }
    if (phaseTwoHasStarted) {
      setIsPhaseTwoActive(true);
    }
  }, 1000);

  useEffect(() => {
    const hash = window?.location?.hash;
    console.log(hash);
    if (hash === "#gift-claim") {
      setShowGiftSection(true);
    }
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {SOLD_OUT ? null : isGeneralSaleActive ? (
          <div className={classes.minterContainer}>
            <Typography
              gutterBottom
              className={classes.title}
              variant={isMobile ? "h4" : "h3"}
            >
              Get your own legend
            </Typography>
            <Typography className={classes.text} variant={"h5"}>
              Mint a maximum of 5 House of Legends NFT’s at a time
            </Typography>
            <Minter
              mint={mint}
              saleIsActive={isGeneralSaleActive}
              isOnCorrectChain={isOnCorrectChain}
              max={5}
            />
          </div>
        ) : (
          <>
            {" "}
            <div className={classes.minterContainer}>
              <Typography
                gutterBottom
                className={classes.title}
                variant={isMobile ? "h4" : "h3"}
              >
                Now Open For Phase 1 + 2
              </Typography>
              <Typography className={classes.text} variant={"h5"}>
                Mint a maximum of 3 House of Legends NFT’s
              </Typography>
              <Typography className={classes.text} variant={"h5"}>
                <span style={{ fontWeight: "bold" }}>
                  Christmas Gift: Mint 3 Legends + Get 4th Legend for FREE!
                </span>
              </Typography>
              <Typography className={classes.text} variant={"h6"}>
                <span style={{ fontWeight: "bold" }}>
                  To claim your FREE additional mint –{" "}
                </span>
                you must mint all 3 at once
              </Typography>
              <Typography className={classes.text} variant={"h6"}>
                (Choose "3 + 1 for FREE" from dropdown menu)
              </Typography>
              <Countdown presaleStart={PHASE_ONE_START} />
              <Minter
                mint={phaseOneMint}
                saleIsActive={isPhaseOneActive}
                isOnCorrectChain={isOnCorrectChain}
                max={3}
                showDiscount
              />
            </div>
            {showGiftSection ? (
              <div className={classes.minterContainer}>
                <Typography
                  gutterBottom
                  className={classes.title}
                  variant={isMobile ? "h4" : "h3"}
                >
                  Token Claim
                </Typography>
                <Typography className={classes.text} variant={"h5"}>
                  Claim Your House of Legends NFT
                </Typography>
                <Countdown presaleStart={PHASE_ONE_START} />
                <Minter
                  mint={giftMint}
                  saleIsActive={isPhaseOneActive}
                  isOnCorrectChain={isOnCorrectChain}
                  max={3}
                />
              </div>
            ) : null}
          </>
        )}
      </div>
      <Typography className={classes.message} variant={isMobile ? "h6" : "h5"}>
        You are stepping into the future with us, and it feels Legendary.
      </Typography>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      background: theme.palette.common.black,
      padding: theme.spacing(8, 3),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(8, 6),
      },
      [theme.breakpoints.up("xl")]: {
        padding: theme.spacing(8, 12),
      },
    },
    content: { maxWidth: 800, width: "100%" },
    title: {
      width: "100%",
      textAlign: "left",
      fontWeight: 700,
      color: theme.palette.common.white,
    },
    text: {
      margin: 0,
      fontWeight: 300,
      color: theme.palette.common.white,
      marginBottom: theme.spacing(2),
      textAlign: "left",
      width: "100%",
      fontFamily: "'Poppins', sans-serif;",
    },
    minterContainer: {
      width: "100%",
      margin: theme.spacing(1, 0, 10),
      padding: theme.spacing(0, 0),
      [theme.breakpoints.up("md")]: {
        margin: theme.spacing(2, 0, 4),
        padding: 0,
      },
    },
    countdownContainer: {
      width: "100%",
      maxWidth: 600,
    },
    message: {
      textAlign: "center",
      fontWeight: 300,
      color: theme.palette.common.white,
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
      fontFamily: "'Poppins', sans-serif;",
    },
  })
);
