import { MerkleTree } from "merkletreejs";
import phaseOne from "../assets/whitelist/phase_1.json";
import phaseTwo from "../assets/whitelist/phase_2.json";
import phaseThree from "../assets/whitelist/phase_3.json";

const keccak256 = require("keccak256");

export function useMerkle() {
  function getTree(array: string[]) {
    const leaves = array.map((x) => keccak256(x));
    const tree = new MerkleTree(leaves, keccak256, { sort: true });
    const root = tree.getHexRoot();
    return [tree, root] as const;
  }

  function getProof(tree: any, leaf: string): string[] {
    return tree.getHexProof(leaf);
  }

  const [phaseOneTree, root1] = getTree(phaseOne);
  const [phaseTwoTree, root2] = getTree(phaseTwo);
  const [giftListTree, root3] = getTree(phaseThree);
  console.log("phaseOneTree", root1);
  // console.log("phaseTwoTree", root2);
  // console.log("giftListTree", root3);

  const treeMap = {
    "1": phaseOneTree,
    "2": phaseTwoTree,
    "3": giftListTree,
  };

  function getLeafAndProof(data: any, phase: string) {
    const leaf = keccak256(data);
    const proof = getProof(treeMap[phase], leaf);
    return { leaf, proof };
  }

  return { getLeafAndProof };
}
