import React, { useState } from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import { Button, TextField, MenuItem } from "@material-ui/core";

type MinterProps = {
  mint: (count: number) => void;
  isOnCorrectChain: boolean;
  saleIsActive: boolean;
  max: number;
  showDiscount?: boolean;
};

export default function Minter({
  mint,
  isOnCorrectChain,
  saleIsActive,
  max,
  showDiscount = false,
}: MinterProps) {
  const classes = useStyles();
  const [count, setCount] = useState(1);

  function handleChange(event) {
    const value = event.target.value;
    setCount(value < 1 ? 1 : value);
  }

  function handleMint() {
    mint(count);
  }

  const mintingDisabled = !isOnCorrectChain || !saleIsActive;
  const amounts = Array.from(Array(max).keys());
  return (
    <div className={classes.root}>
      <div className={classes.mintContainer}>
        <TextField
          label={"Amount of NFTs"}
          classes={{ root: classes.selectInput }}
          variant={"outlined"}
          id="amount-selection"
          select
          value={count}
          onChange={handleChange}
          helperText="Please select the amount of tokens you wish to mint"
        >
          {amounts.map((option) => {
            const value = option + 1;
            return (
              // TODO dont show 3 + 1  on public sale
              <MenuItem key={value} value={value}>
                {value === 3 && showDiscount ? "3 + 1 for FREE" : value}
              </MenuItem>
            );
          })}
        </TextField>
        <Button
          variant={"contained"}
          size={"large"}
          color={"primary"}
          className={classes.mintButton}
          onClick={handleMint}
          disabled={mintingDisabled}
        >
          Mint Now
        </Button>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiInputLabel-outlined": {
        color: "white",
        fontSize: "1rem",
      },
      "& .MuiFormHelperText-root": {
        color: "white",
        fontSize: "1rem",
      },
      "& .MuiSelect-iconOutlined": {
        color: "white",
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: 0,
        fontSize: "1rem",
        color: theme.palette.common.white,
        "& fieldset": {
          borderColor: "white",
        },
        "&:hover fieldset": {
          borderColor: "white",
        },
        "&.Mui-focused fieldset": {
          borderColor: "white",
        },
      },
    },
    mintButton: {
      marginTop: theme.spacing(3),
      padding: theme.spacing(1.5, 6.5),
      fontWeight: 700,
      fontFamily: "'Poppins', sans-serif;",
      textTransform: "uppercase",
      background: theme.palette.primary.main,
      color: theme.palette.common.black,
      fontSize: theme.typography.h3.fontSize,
      borderRadius: 0,
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.h5.fontSize,
      },
      "&.MuiButton-contained.Mui-disabled": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.black,
        opacity: 0.7,
      },
    },
    mintContainer: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      width: "100%",
    },
    selectInput: {
      marginTop: theme.spacing(2),
      color: theme.palette.common.white,
    },
    arrow: {
      color: "white",
    },
  })
);
