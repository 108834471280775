import React, { useEffect, useState } from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import moment from "moment";
import { Skeleton } from "@material-ui/lab";
import useInterval from "../../../utils/useInterval";

type SupplyCounterProps = {
  fetchPublicAmountMinted: () => Promise<number>;
};

export default function SupplyCounter({
  fetchPublicAmountMinted,
}: SupplyCounterProps) {
  const classes = useStyles();
  const [amountLeft, setAmountLeft] = useState<number | undefined>(undefined);

  async function getAmountMinted() {
    const amountMinted = await fetchPublicAmountMinted();
    const totalPublicSupply = 9495;
    setAmountLeft(totalPublicSupply - amountMinted);
  }

  useInterval(getAmountMinted, 3000);
  useEffect(() => {
    getAmountMinted();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.counter}>
        <Typography variant={"h4"} color={"inherit"} className={classes.title}>
          All Legends Have Been Minted
        </Typography>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      fontWeight: 700,
      fontFamily: "'Poppins', sans-serif;",
      marginRight: theme.spacing(1),
      color: theme.palette.background.green,
      textAlign: "center",
    },
    counter: {
      display: "flex",
      justifyContent: "flex-start",
    },
    countContainer: {
      textAlign: "center",
      marginRight: theme.spacing(1),
    },
    date: {
      textTransform: "uppercase",
    },
    count: {
      fontWeight: 300,
      color: theme.palette.background.green,
      fontFamily: "'Poppins', sans-serif;",
    },
    countText: {
      fontWeight: 300,
      color: theme.palette.common.white,
      fontFamily: "'Poppins', sans-serif;",
    },
  })
);
