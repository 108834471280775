import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import {
  Twitter as TwitterIcon,
  Instagram as InstagramIcon,
} from "@material-ui/icons";
import DiscordIcon from "../../../assets/images/discord-icon.svg";
import { openInNewTab } from "../../../utils/openLink";
import { config } from "../../../config/config";

export default function SocialMediaLinks({
  className,
}: {
  className?: string;
}) {
  const classes = useStyles();
  return (
    <div className={className}>
      <IconButton
        color={"inherit"}
        size={"medium"}
        onClick={() => openInNewTab(config.twitterUrl)}
      >
        <TwitterIcon fontSize={"large"} />
      </IconButton>
      <IconButton
        color={"inherit"}
        size={"medium"}
        onClick={() => openInNewTab(config.discordUrl)}
      >
        <img
          className={classes.discordIcon}
          src={DiscordIcon}
          alt={"link to discord"}
        />
      </IconButton>
      <IconButton
        color={"inherit"}
        size={"medium"}
        onClick={() => openInNewTab(config.instagramUrl)}
      >
        <InstagramIcon fontSize={"large"} />
      </IconButton>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    discordIcon: {
      width: 35,
      height: 35,
    },
  })
);
